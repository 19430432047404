(function(){
	window.CustomCaptcha = {
		id: null,
		logo: "domain",
		name: "g-recaptcha-response",
		text: "reCAPTCHA",
		descriptions: {
			"hu": "Nem vagyok robot",
			"en": "I'm not a robot",
			"de": "Ich bin kein Roboter.",
			"sk": "Nie som robot",
			"ro": "Nu sunt robot",
			"hr": "Nisam robot",
			"fr": "Je ne suis pas un robot"
		},
		lang: "auto",
		theme: "light",
		_prefix: "_cc-",
		_last_response: ""
	};
	if(typeof grecaptcha != "function"){
		var scr = document.createElement('script');
		scr.src = "https://www.google.com/recaptcha/api.js?hl=en&render=explicit";
		scr.onload = function(){
			setTimeout(function(){
				if(typeof grecaptcha != "function" || typeof grecaptcha.render != "function"){
					var scr2 = document.createElement('script');
					scr2.src = "https://www.google.com/recaptcha/api.js?hl=en&render=explicit";
					document.body.appendChild(scr2);
				}
			},1000);
		};
		document.body.appendChild(scr);
	}
	if(!document.getElementById('grecaptcha-badge')){
		var grc = document.createElement('div');
		grc.id = "grecaptcha-badge";
		document.body.appendChild(grc);
	}
	CustomCaptcha.reset = function(code=null){
		if(!CustomCaptcha._initialized){
			throw Error("CustomCaptcha is not initialized!");
		}
		if(code){
			document.querySelectorAll('.custom-captcha').forEach(function(el,i){
				if(el.querySelector(`.${CustomCaptcha._prefix}code`).value==code){
					el.querySelector(`.${CustomCaptcha._prefix}code`).value = '';
					el.classList.remove(`${CustomCaptcha._prefix}loaded`);
				}
			});
		} else {
			CustomCaptcha._last_response = "";
			document.querySelectorAll('.custom-captcha').forEach(function(el,i){
				el.querySelector(`.${CustomCaptcha._prefix}code`).value = '';
				el.classList.remove(`${CustomCaptcha._prefix}loaded`);
			});
		}
		/*grecaptcha.reset();
		setTimeout(function(){
			document.querySelector('#grecaptcha-badge textarea').removeAttribute('name');
		},100);*/
	}
	CustomCaptcha.init = function(opt){
		if(CustomCaptcha._initialized){
			throw Error("CustomCaptcha is already initialized!");
		}
		if(typeof opt != "object"){
			if(!opt || opt.length<1){
				throw Error("Option 'siteKey' is required!");
			}
			CustomCaptcha.siteKey = opt;
		} else {
			if(!opt.siteKey){
				throw Error("Option 'siteKey' is required!");
			}
			CustomCaptcha.siteKey = opt.siteKey;
			var options = ["logo","name","id","text","lang","theme","label"];
			options.map(function(option){
				if(!!opt[option]) {
					CustomCaptcha[option] = opt[option];
				}
			});
		}
		CustomCaptcha._initialized = true;
		if(typeof grecaptcha === 'undefined') {
			grecaptcha = {};
			grecaptcha.ready = function(cb){
				if(typeof grecaptcha.render === 'undefined') {
					const c = '___grecaptcha_cfg';
					window[c] = window[c] || {};
					(window[c]['fns'] = window[c]['fns']||[]).push(cb);
				} else {
					cb();
				}
			}
		}
		grecaptcha.ready(function(){
			grecaptcha.render("grecaptcha-badge",{sitekey:CustomCaptcha.siteKey,badge:"bottomright",size:"invisible"});
			document.querySelector('#grecaptcha-badge textarea').removeAttribute('name');
		});
		document.querySelectorAll('captcha').forEach(function(el,i){
			var dark = (el.getAttribute('theme') || CustomCaptcha.theme)=="dark";
			var lang = (el.getAttribute('lang') || CustomCaptcha.lang).toLowerCase();
			lang = lang=="auto" ? navigator.language.split('-')[0] : lang;
			var description = el.getAttribute('label') || CustomCaptcha.label || CustomCaptcha.descriptions[lang] || CustomCaptcha.descriptions.en;
			var default_logo = "https://i.imgur.com/fzqbY05.png";
			var logo = encodeURI(el.getAttribute('logo') || CustomCaptcha.logo);
			if(logo=="domain") {
				if(window.location.host.length>0){
					favicon_api = "https://icons.duckduckgo.com/ip3/"+window.location.host+".ico";
					favicon = "http://"+window.location.host+"/favicon.ico";
					logo = "https://images.weserv.nl/?maxage=1d&url="+encodeURI(favicon)+"&default="+encodeURI(default_logo);
					logo = "https://images.weserv.nl/?maxage=1d&url="+encodeURI(favicon_api)+"&default="+encodeURIComponent(logo);
				} else {
					logo = default_logo;
				}
			} else {
				var a = document.createElement('a');
				a.href = logo;
				logo = a.href;
				a.remove();
				var url = false;
				try {
					url = new URL(logo);
				} catch(e){}
				if(url&&url.hostname!=""&&url.hostname!="localhost"){
					logo = "https://images.weserv.nl/?maxage=1d&url="+encodeURI(logo)+"&default="+encodeURI(default_logo);
				} else {
					logo = default_logo;
				}
			}
			var name = el.getAttribute('name') || CustomCaptcha.name;
			var id = el.getAttribute('id') || CustomCaptcha.id || false;
			var text = el.getAttribute('text') || CustomCaptcha.text;
			text = text.length<1 ? "reCAPTCHA" : text;
			var _container = document.createElement('div');
				_container.className = el.className;
				_container.classList.add("custom-captcha");
				if(el.hasAttribute('logo-rounded')) _container.classList.add(`${CustomCaptcha._prefix}rounded`);
				if(dark) _container.classList.add(`${CustomCaptcha._prefix}dark`);
				if(el.hasAttribute('required')) _container.setAttribute('required', true);
				var _spinner = document.createElement('div');
					_spinner.classList.add(`${CustomCaptcha._prefix}spinner`);
					var _checkmark = document.createElement('button');
						_checkmark.classList.add(`${CustomCaptcha._prefix}checkmark`);
						_checkmark.type = "button";
						_checkmark.innerHTML = "<span>&nbsp;</span>";
						_spinner.appendChild(_checkmark);
					_container.appendChild(_spinner);
				var _text = document.createElement('div');
					_text.classList.add(`${CustomCaptcha._prefix}text`);
					_text.innerText = description;
					_container.appendChild(_text);
				var _logo = document.createElement('div');
					_logo.classList.add(`${CustomCaptcha._prefix}logo`);
					if(el.hasAttribute('text-absolute')) _logo.classList.add(`${CustomCaptcha._prefix}absolute`);
					var _logo_img = document.createElement('img');
						_logo_img.setAttribute('draggable', false);
						_logo_img.src = logo;
						_logo.appendChild(_logo_img);
					var _brand = document.createElement('p');
						_brand.innerText = text;
						_logo.appendChild(_brand);
					_container.appendChild(_logo);
				var _code = document.createElement('textarea');
					_code.classList.add(`${CustomCaptcha._prefix}code`);
					_code.setAttribute('tabindex', '-1');
					_code.name = name;
					if(id) _code.id = id;
					if(el.hasAttribute('required')) _code.setAttribute('required', true);
					_container.appendChild(_code);
				el.replaceWith(_container);
		});
		document.addEventListener("click",function(e){
			if(e.target.matches(`.custom-captcha:not(.${CustomCaptcha._prefix}loading):not(.${CustomCaptcha._prefix}loaded) :where(.${CustomCaptcha._prefix}checkmark, .${CustomCaptcha._prefix}checkmark *)`)) {
				var captcha = e.target.closest('.custom-captcha');
				captcha.classList.add(`${CustomCaptcha._prefix}loading`);
				grecaptcha.execute({action:'submit'}).then(function(){
					setTimeout(function(){
						var captcha_token = grecaptcha.getResponse();
						if(captcha_token!=""){
							CustomCaptcha._last_response = captcha_token;
						} else if(CustomCaptcha._last_response!="") {
							captcha_token = CustomCaptcha._last_response;
						}
						document.querySelector('#grecaptcha-badge textarea').value = "";
						captcha.classList.remove(`${CustomCaptcha._prefix}loading`);
						captcha.querySelector(`.${CustomCaptcha._prefix}code`).value = captcha_token;
						if(captcha_token!=""){
							captcha.classList.add(`${CustomCaptcha._prefix}loaded`);
							setTimeout(function(){
								CustomCaptcha.reset(captcha_token);
							},1000*60*1.5);
						} else {
							CustomCaptcha.reset();
						}
					},1000);
				}).catch(function(){
					CustomCaptcha.reset();
					document.querySelector('#grecaptcha-badge textarea').value = "";
				});
			}
		});
	};
})();